<button
  color="primary"
  mat-icon-button
  matTooltip="Resource permissions"
  matTooltipPosition="above"
  cdkOverlayOrigin
  #infoButton="cdkOverlayOrigin"
  (click)="isOpen = !isOpen">
  <mat-icon>lock</mat-icon>
</button>

<ng-template
  cdkConnectedOverlay
  [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
  [cdkConnectedOverlayHasBackdrop]="true"
  [cdkConnectedOverlayScrollStrategy]="scrollStrategy"
  [cdkConnectedOverlayPositions]="INFOBOX_POSITIONS"
  [cdkConnectedOverlayOrigin]="infoButton"
  [cdkConnectedOverlayOpen]="isOpen"
  (detach)="isOpen = false"
  (backdropClick)="isOpen = false">
  <div class="overlay-info-box">
    <table>
      <tr>
        <th class="first-col">{{ 'resource.permissions.groups.label' | translate }}</th>
        <th *ngFor="let icon of PERMISSION_HEADERS">
          <mat-icon [matTooltip]="icon.label | translate" matTooltipPosition="above"> {{icon.icon}} </mat-icon>
        </th>
      </tr>
      <tr
        *ngFor="let group of permissionGroups"
        [class.border-top]="group.group === 'UsersPermissions' || group.group === 'CustomGroup'">
        <td class="first-col">{{group.label | translate}}</td>
        <td *ngFor="let permission of group.permissions; let i = index">
          <mat-icon
            class="status"
            [class.checked]="permission.granted"
            [matTooltip]="PERMISSION_HEADERS[i]?.label || '' | translate"
            matTooltipPosition="above">
            {{ permission.granted ? 'radio_button_checked' : 'radio_button_unchecked'}}
          </mat-icon>
        </td>
      </tr>
    </table>
  </div>
</ng-template>

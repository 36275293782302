<!-- in case of changing not own password: input for requester password = own form -->
<!-- System admin must enter his password before changing the user's password. -->
<form [formGroup]="confirmForm" class="form-content" *ngIf="!showPasswordForm" (ngSubmit)="nextStep()">
  <h4 class="mat-subtitle-2">As system admin, please enter your password before updating user's password.</h4>
  <mat-form-field class="large-field">
    <input
      matInput
      [type]="showRequesterPassword ? 'text' : 'password'"
      [formControl]="confirmForm.controls['requesterPassword']"
      autocomplete="old-password"
      placeholder="{{
                'form.user.general.requesterPassword' | translate
            }}" />

    <button
      type="button"
      matSuffix
      mat-icon-button
      class="input-icon"
      attr.aria-label="{{
                showRequesterPassword ? 'Hide password' : 'Show password'
            }}"
      (click)="showRequesterPassword = !showRequesterPassword">
      <mat-icon> {{ showRequesterPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
    </button>
    <mat-error *ngIf="confirmForm.controls['requesterPassword'].hasError('incorrectPassword')">
      Incorrect password.
    </mat-error>
  </mat-form-field>
  <!-- action buttons: reset and submit -->
  <div class="form-panel large-field">
    <span>
      <button mat-button type="button" (click)="closeDialog.emit()">{{ 'form.action.cancel' | translate }}</button>
    </span>
    <span class="fill-remaining-space"></span>
    <button mat-button type="submit" color="primary" [disabled]="!confirmForm.valid">
      {{ 'form.action.next' | translate }}
    </button>
  </div>
</form>

<!-- main form -->
<form [formGroup]="form" class="form-content" *ngIf="showPasswordForm" (ngSubmit)="submitData()">
  <!-- Password forms should have (optionally hidden) username fields for accessibility: (More info: https://goo.gl/9p2vKq) -->
  <mat-form-field class="large-field hidden">
    <input matInput [formControl]="form.controls['username']" autocomplete="username" />
  </mat-form-field>

  <!-- in case of changing own password: input for old password -->
  <mat-form-field class="large-field" [class.hidden]="!updateOwn">
    <input
      matInput
      [type]="showRequesterPassword ? 'text' : 'password'"
      [formControl]="form.controls['requesterPassword']"
      autocomplete="current-password"
      placeholder="{{
                'form.user.general.oldPassword' | translate
            }}" />

    <button
      type="button"
      matSuffix
      mat-icon-button
      class="input-icon"
      attr.aria-label="{{
                showRequesterPassword ? 'Hide password' : 'Show password'
            }}"
      (click)="showRequesterPassword = !showRequesterPassword">
      <mat-icon> {{ showRequesterPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
    </button>
    <mat-error *ngIf="form.controls['requesterPassword'].hasError('incorrectPassword')">
      Incorrect password.
    </mat-error>
  </mat-form-field>

  <!-- two password fields: password & confirmPassword -->
  <!-- password -->
  <mat-form-field class="large-field">
    <input
      matInput
      [type]="showPassword ? 'text' : 'password'"
      [formControl]="form.controls['password']"
      autocomplete="new-password"
      placeholder="{{
                'form.user.general.newPassword' | translate
            }}" />

    <button
      type="button"
      matSuffix
      mat-icon-button
      class="input-icon"
      attr.aria-label="{{
                showPassword ? 'Hide password' : 'Show password'
            }}"
      (click)="showPassword = !showPassword">
      <mat-icon> {{ showPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
    </button>
    <mat-hint *ngIf="formErrors.password"> {{ formErrors.password }}</mat-hint>
  </mat-form-field>

  <!-- confirm password -->

  <mat-form-field
    class="large-field"
    [class.mat-form-field-invalid]="
            formErrors.confirmPassword || !matchingPasswords
        ">
    <input
      matInput
      [type]="showConfirmPassword ? 'text' : 'password'"
      [class.error]="formErrors.confirmPassword || !matchingPasswords"
      [formControl]="form.controls['confirmPassword']"
      autocomplete="confirm-password"
      placeholder="{{
                'form.user.general.confirmPassword' | translate
            }}" />

    <button
      type="button"
      matSuffix
      mat-icon-button
      class="input-icon"
      attr.aria-label="{{
                showConfirmPassword ? 'Hide password' : 'Show password'
            }}"
      (click)="showConfirmPassword = !showConfirmPassword">
      <mat-icon> {{ showConfirmPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
    </button>
    <mat-hint *ngIf="formErrors.confirmPassword || !matchingPasswords">
      {{ formErrors.confirmPassword }}
      <span *ngIf="matchingPasswords"> {{ validationMessages.confirmPassword.match }}</span>
    </mat-hint>
  </mat-form-field>

  <!-- action buttons: reset and submit -->
  <div class="form-panel large-field" *ngIf="user">
    <button mat-button color="primary" type="reset">{{ 'form.action.reset' | translate }}</button>
    <span class="fill-remaining-space"></span>
    <button mat-raised-button type="submit" color="primary" [disabled]="!form.valid || !matchingPasswords">
      <app-progress-indicator [color]="'white'" [status]="0" *ngIf="loading" class="submit-progress" />
      {{ !loading && error ? ('form.action.retry' | translate) : ('form.action.update' | translate) }}
    </button>
  </div>
</form>
